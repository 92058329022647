<template>
  <div>
    <b-card v-if="result.order">
      <b-row>
        <b-col cols="12" lg="2">
          <b-img :src="result.order.service_image" width="100" alt="Card image cap" />
        </b-col>
        <b-col cols="12" lg="10">
          <table class="w-100">
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="FileTextIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Nomor Invoice</span>
              </th>
              <td class="pb-50">
                {{ result.order.invoice_number ? result.order.invoice_number : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="CheckIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Nama Jasa</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.order.service_name ? result.order.service_name : '-' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="StarIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Nama Paket</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.order.package_name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="AlertTriangleIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Masalah</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.problem }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="AwardIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Solusi</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.expected_solution }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="AwardIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Pembeli</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.order.buyer.name }} - {{ result.order.buyer.email }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 d-lg-flex">
                <feather-icon icon="AwardIcon" class="mr-75 d-none d-lg-flex" />
                <span class="font-weight-bold">Talenta</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.order.talent.name }} - {{ result.order.talent.email }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    
    <b-card>
      <b-carousel id="gallery" controls indicators>
        <b-carousel-slide v-for="(image, indexImg) in result.images" :key="indexImg" :img-src="image" />
      </b-carousel>
    </b-card>

    <b-card>
      <div class="chat-container">
        <div class="chat-header">
          <h5 class="mb-0">Riwayat Percakapan</h5>
        </div>
        <div class="chat-body">
          <template v-if="chats && chats.length > 0">
            <div v-for="(chat, index) in chats" :key="index" class="chat-date-group">
              <div class="chat-date">{{ chat.date }}</div>
              <div v-for="(item, itemIndex) in chat.chats" :key="itemIndex">
                <div :class="{'message': true, 'buyer-message': item.sender_type === 'buyer', 'talent-message': item.sender_type === 'talent'}">
                  <div class="message-header">
                    <span class="sender">{{ item.sender_type === 'buyer' ? 'Pembeli' : 'Talenta' }}</span>
                    <small class="time">{{ item.time }}</small>
                  </div>
                  <div class="message-content">
                    <!-- Text Message -->
                    <div v-if="item.content_text" class="text-content">
                      {{ item.content_text }}
                    </div>
                    <!-- Image Message -->
                    <div v-if="item.content_file && item.content_file_type === 'image'" class="image-content">
                      <b-img
                        :src="item.content_file"
                        fluid
                        thumbnail
                        class="chat-image"
                        @click="showImagePreview(item.content_file)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="no-chat-message">
            <feather-icon
              icon="MessageSquareIcon"
              size="32"
              class="mb-1"
            />
            <p class="mb-0">Tidak ada history chat</p>
          </div>
        </div>
      </div>
    </b-card>
    
    <!-- Image Preview Modal -->
    <b-modal
      v-model="showPreview"
      hide-footer
      centered
      size="lg"
      title="Preview Gambar"
    >
      <b-img
        :src="previewImage"
        fluid
        class="preview-image"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BImg,
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BImg,
    BModal,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
    chats: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      showPreview: false,
      previewImage: '',
    }
  },
  methods: {
    showImagePreview(imageUrl) {
      this.previewImage = imageUrl
      this.showPreview = true
    },
  },
}
</script>

<style scoped>
.chat-container {
  max-width: 100%;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.chat-header {
  background-color: #fff;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.chat-body {
  padding: 1.5rem;
  height: 500px;
  overflow-y: auto;
}

.chat-date-group {
  margin-bottom: 1.5rem;
}

.chat-date {
  text-align: center;
  margin: 1rem 0;
  color: #6c757d;
  font-size: 0.875rem;
}

.message {
  margin-bottom: 1rem;
  max-width: 80%;
}

.message-header {
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sender {
  font-weight: 600;
  font-size: 0.875rem;
}

.time {
  color: #6c757d;
}

.message-content {
  padding: 0.75rem;
  border-radius: 8px;
  position: relative;
}

.text-content {
  word-break: break-word;
}

.image-content {
  margin-top: 0.5rem;
}

.chat-image {
  max-width: 200px;
  cursor: pointer;
  transition: transform 0.2s;
}

.chat-image:hover {
  transform: scale(1.05);
}

.preview-image {
  width: 100%;
  height: auto;
}

.buyer-message {
  margin-right: auto;
}

.buyer-message .message-content {
  background-color: #fff;
  border: 1px solid #e9ecef;
}

.talent-message {
  margin-left: auto;
}

.talent-message .message-content {
  background-color: #dcf8c6;
  border: 1px solid #c3e6cb;
}

/* Scrollbar Styling */
.chat-body::-webkit-scrollbar {
  width: 6px;
}

.chat-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.chat-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-chat-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #6c757d;
  text-align: center;
}

.no-chat-message svg {
  color: #adb5bd;
  margin-bottom: 0.5rem;
}

.no-chat-message p {
  font-size: 0.875rem;
}
</style>
