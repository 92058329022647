<!-- Detail Report -->
<template>
  <div>
    <b-card v-if="report">
      <!-- Header -->
      <b-card-header class="d-flex justify-content-between align-items-center">
        <h5 class="mb-0">Report Details</h5>
        <b-button
          variant="primary"
          size="sm"
          @click="goBack"
        >
          <feather-icon icon="ArrowLeftIcon" class="mr-50" />
          Back to List
        </b-button>
      </b-card-header>

      <b-card-body>
        <!-- Reporter Info -->
        <b-row class="mb-2">
          <b-col cols="12" md="6">
            <h6 class="mb-1">Reporter Information</h6>
            <div class="d-flex mb-1">
              <span class="font-weight-bold mr-1">Name:</span>
              <span>{{ report.reporter ? report.reporter.name : 'N/A' }}</span>
            </div>
            <div class="d-flex mb-1">
              <span class="font-weight-bold mr-1">Email:</span>
              <span>{{ report.reporter ? report.reporter.email : 'N/A' }}</span>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <h6 class="mb-1">Report Details</h6>
            <div class="d-flex mb-1">
              <span class="font-weight-bold mr-1">Created:</span>
              <span>{{ formatDate(report.created_at) }}</span>
            </div>
            <div class="d-flex mb-1">
              <span class="font-weight-bold mr-1">Status:</span>
              <b-badge :variant="getStatusVariant(report.status)">
                {{ report.status }}
              </b-badge>
            </div>
          </b-col>
        </b-row>

        <!-- Report Content -->
        <b-row>
          <b-col cols="12">
            <h6 class="mb-1">Report Content</h6>
            <div class="d-flex mb-1">
              <span class="font-weight-bold mr-1">Reason:</span>
              <span>{{ report.reason ? report.reason.reason_text : 'N/A' }}</span>
            </div>
            <div class="mb-2">
              <span class="font-weight-bold d-block mb-1">Description:</span>
              <p class="mb-0">{{ report.additional_description || 'No description provided' }}</p>
            </div>
          </b-col>
        </b-row>

        <!-- Talent Service Info -->
        <b-row v-if="report.talent_service">
          <b-col cols="12">
            <h6 class="mb-2">Reported Service</h6>
            <b-card class="mb-0">
              <div class="d-flex mb-1">
                <span class="font-weight-bold mr-1">Service Title:</span>
                <span>{{ report.talent_service.name }}</span>
              </div>
              <div class="d-flex mb-1">
                <span class="font-weight-bold mr-1">Service Owner:</span>
                <span>{{ report.talent_service.talent ? report.talent_service.talent.name : 'N/A' }}</span>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Loading State -->
    <b-card v-else>
      <b-skeleton-wrapper>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BBadge,
  BRow,
  BCol,
  BSkeleton,
  BSkeletonWrapper,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import axios from '@/libs/axios'

export default {
  name: 'DetailReport',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BBadge,
    BRow,
    BCol,
    BSkeleton,
    BSkeletonWrapper,
  },
  setup(props, { root }) {
    const report = ref(null)

    // Format Date
    const formatDate = (date) => {
      if (!date) return 'N/A'
      const options = { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
      return new Date(date).toLocaleDateString('en-US', options)
    }

    // Fetch Report Details
    const fetchReport = async (id) => {
      try {
        const response = await axios.get(`/reports/${id}`)
        report.value = response.data.data
      } catch (error) {
        console.error('Error fetching report details:', error)
        root.$bvToast.toast('Error fetching report details', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    }

    // Get Status Badge Variant
    const getStatusVariant = status => {
      if (!status) return 'primary'
      
      const variants = {
        pending: 'warning',
        resolved: 'success',
        rejected: 'danger',
      }
      return variants[status.toLowerCase()] || 'primary'
    }

    // Navigation
    const goBack = () => {
      root.$router.push({ name: 'reports.index' })
    }

    onMounted(() => {
      const { id } = root.$route.params
      if (id) fetchReport(id)
    })

    return {
      report,
      getStatusVariant,
      formatDate,
      goBack,
    }
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  .row:not(:last-child) {
    border-bottom: 1px solid #ebe9f1;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
</style> 