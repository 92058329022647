var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',[_vm._v("Reports List")])]),_c('div',{staticClass:"m-2"},[(_vm.reports.length === 0)?_c('div',[_vm._v("Loading...")]):_c('vue-good-table',{attrs:{"mode":"remote","total-rows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.reports,"rtl":false,"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm,
          searchFn: _vm.customSearch
        },"pagination-options":{
          enabled: true,
          perPage: _vm.perPage,
          position: 'bottom'
        },"line-numbers":true,"sort-options":{
          enabled: true,
          initialSortBy: { field: 'created_at', type: 'desc' }
        },"compactMode":"","styleClass":"vgt-table striped bordered"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-search":_vm.onSearch},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'actions')?_c('span',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.viewReport(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)],1):(props.column.field === 'talent_service.name')?_c('span',[_vm._v(" "+_vm._s((props.row.talent_service && props.row.talent_service.name) || 'N/A')+" ")]):(props.column.field === 'reason.reason_text')?_c('span',[_vm._v(" "+_vm._s((props.row.reason && props.row.reason.reason_text) || 'N/A')+" ")]):(props.column.field === 'reporter.name')?_c('span',[_vm._v(" "+_vm._s((props.row.reporter && props.row.reporter.name) || 'N/A')+" ")]):(props.column.field === 'additional_description')?_c('span',[_vm._v(" "+_vm._s(props.row.additional_description || 'No description provided')+" ")]):(props.column.field === 'created_at')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.created_at))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] || 'N/A')+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No reports found ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }