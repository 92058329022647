<template>
  <div>
    <b-row>
      <!-- Information Complain -->
      <b-col
        cols="12"
        lg="8"
      >
        <b-tabs>
          <b-tab
            active
            title="Komplain"
          >
          
            <ComplaintUser :result="result" :chats="chats" />
          </b-tab>
          <b-tab
            v-if="result.feedback"
            title="Feedback"
          >
            <ComplaintFeedback :result="result" />
          </b-tab>
        </b-tabs>
      </b-col>
      <!-- Information Sender & Action Komplain -->
      <b-col
        cols="12"
        lg="4"
        class="mt-lg-4"
      >
        <!-- Information Winner -->
        <b-card
          v-if="result.winner !== null"
          class="winner-card"
        >
          <div class="winner-header d-flex align-items-center mb-2">
            <feather-icon
              icon="AwardIcon"
              class="text-warning mr-1"
              size="24"
            />
            <h3 class="mb-0">Pemenang Komplain</h3>
          </div>

          <div class="winner-content">
            <div class="winner-badge mb-2">
              <span :class="['badge', 'badge-light-success', 'p-1']">
                <feather-icon
                  icon="CheckCircleIcon"
                  size="16"
                  class="mr-50"
                />
                {{ result.winner === 'buyer' ? 'Pembeli' : 'Talenta' }}
              </span>
            </div>

            <div class="winner-details">
              <div class="detail-item d-flex align-items-center mb-1">
                <feather-icon
                  icon="FileTextIcon"
                  size="16"
                  class="text-muted mr-1"
                />
                <span class="font-weight-bold mr-1">Catatan Admin:</span>
                <span>{{ result.admin_note }}</span>
              </div>
            </div>
          </div>
        </b-card>
        <!-- Information Sender -->
        <b-card
          v-if="result.sender"
        >
          <div class="d-flex justify-content-start">
            <b-img
              :src="result.sender.photo_url"
              width="80"
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ result.sender.name ? result.sender.name : '-' }}
                </h4>
                <span class="card-text">{{ result.sender.email }}</span>
              </div>
            </div>
          </div>
        </b-card>
        <!-- Action Komplain -->
        <b-card>
          <b-card-title class="mb-2">Tindakan Komplain</b-card-title>
          
          <div class="complaint-action-form">
            <div class="form-group">
              <label for="select-default" class="font-weight-bold">Pemenang Komplain</label>
              <b-form-select
                id="select-default"
                v-model="formPayload.winner"
                :options="options"
                class="mb-1"
              />
            </div>

            <div class="form-group">
              <label for="amount-type" class="font-weight-bold">Tipe Jumlah</label>
              <b-form-select
                id="amount-type"
                v-model="formPayload.amount_type"
                :options="amountTypeOptions"
                class="mb-1"
              />
            </div>

            <div class="form-group">
              <label for="amount" class="font-weight-bold">
                Jumlah {{ formPayload.amount_type === 'percent' ? '(%)' : '' }}
              </label>
              <div v-if="result.order" class="text-muted mb-1">
                <small>Total Order: {{ formatAmount(result.order.total) }}</small>
              </div>
              <div class="amount-input-wrapper">
                <div v-if="formPayload.amount_type === 'fixed'" class="input-prefix">Rp</div>
                <div v-if="formPayload.amount_type === 'percent'" class="input-suffix">%</div>
                <b-form-input
                  id="amount"
                  v-model="formPayload.amount"
                  type="number"
                  :min="0"
                  :max="getMaxAmount"
                  :placeholder="formPayload.amount_type === 'percent' ? 'Masukkan persentase (maks. 100%)' : '   Masukkan nominal'"
                  class="form-control-lg"
                  @input="validateAmount"
                />
              </div>
              
              <div v-if="formPayload.amount && result.order" class="amount-preview">
                <div class="preview-content">
                  <feather-icon
                    icon="InfoIcon"
                    size="18"
                    class="text-muted"
                  />
                  <div class="preview-text">
                    <template v-if="formPayload.amount_type === 'percent'">
                      <div class="calculation">
                        {{ formPayload.amount }}% dari {{ formatAmount(result.order.total) }}
                      </div>
                      <div class="total">
                        Total: {{ formatAmount(calculateAmount) }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="total">
                        Total: {{ formatAmount(formPayload.amount) }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <small v-if="amountError" class="text-danger">{{ amountError }}</small>
            </div>

            <div class="form-group">
              <label for="textarea-default" class="font-weight-bold">Keterangan</label>
              <b-form-textarea
                id="textarea-default"
                v-model="formPayload.description"
                placeholder="Masukkan keterangan tindakan"
                rows="3"
                class="mb-1"
              />
            </div>

            <div class="d-flex justify-content-end mt-2">
              <b-button
                v-if="checkPermission('action komplain') && !isLoading"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex align-items-center"
                :disabled="!isFormValid"
                @click="createItem"
              >
                <feather-icon
                  icon="SaveIcon"
                  size="16"
                  class="mr-1"
                />
                Simpan Tindakan
              </b-button>
              <b-button
                v-if="isLoading"
                variant="primary"
                disabled
              >
                <b-spinner small class="mr-1" />
                Menyimpan...
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BFormTextarea,
  BFormSelect,
  BButton,
  BTabs,
  BTab,
  BCardTitle,
  BSpinner,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroupText,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ComplaintUser from '@/components/Complaint/ComplaintUser.vue'
import ComplaintFeedback from '@/components/Complaint/ComplaintFeedback.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormTextarea,
    BFormSelect,
    BButton,
    BTabs,
    BTab,
    ComplaintUser,
    ComplaintFeedback,
    BCardTitle,
    BSpinner,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      result: {},
      isLoading: false,
      formPayload: {
        winner: 'buyer',
        description: '',
        amount_type: 'fixed',
        amount: '',
      },
      amountError: '',
      options: [
        { value: 'buyer', text: 'Pembeli' },
        { value: 'talent', text: 'Talenta' },
      ],
      amountTypeOptions: [
        { value: 'fixed', text: 'Nominal Tetap' },
        { value: 'percent', text: 'Persentase' },
      ],
      chats: [],
    }
  },
  created() {
    this.getData()
  },
  computed: {
    calculateAmount() {
      if (!this.formPayload.amount || !this.result.order) return 0
      
      if (this.formPayload.amount_type === 'percent') {
        return (this.result.order.total * this.formPayload.amount) / 100
      }
      
      return this.formPayload.amount
    },
    getMaxAmount() {
      if (!this.result.order) return 0
      return this.formPayload.amount_type === 'percent' ? 100 : this.result.order.total
    },
    isFormValid() {
      return this.formPayload.winner && 
             this.formPayload.amount && 
             this.formPayload.description &&
             Number(this.formPayload.amount) > 0
    }
  },
  watch: {
    'formPayload.amount_type': {
      handler(newType) {
        this.formPayload.amount = ''
        this.amountError = ''
      }
    }
  },
  methods: {
    formatAmount(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value)
    },
    cleanUpForm() {
      this.formPayload = {
        winner: 'buyer',
        description: '',
        amount_type: 'fixed',
        amount: '',
      }
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
    createItem() {
      const form = this.preparePayload()
      this.isLoading = true

      this.$http.post(`/admin/complain/${this.id}/set-action`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.getData()
          successNotification(this, 'Success', 'Pemenang komplain berhasil di tambahkan.')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response?.data?.meta?.validations) {
            this.validations = error.response.data.meta.validations
          }
          // Show error message from API response
          if (error.response?.data?.meta?.messages?.length > 0) {
            errorNotification(this, 'Error', error.response.data.meta.messages[0])
          } else {
            errorNotification(this, 'Error', 'Terjadi kesalahan saat memproses komplain.')
          }
          this.isLoading = false
        })
    },
    getData() {
      this.$http.get(`/admin/complain/${this.id}`)
        .then(response => {
          this.result = response.data.data
          this.$http.get(`/admin/chat?conversation_uuid=${this.result.conversation_uuid}`)
            .then(res => {
              console.log('resarea')
              console.log(res)
              this.chats = res.data.data.chats
            })

        })
    },
    validateAmount(value) {
      this.amountError = ''
      const numValue = Number(value)
      
      if (this.formPayload.amount_type === 'percent') {
        if (numValue > 100) {
          this.amountError = 'Persentase tidak boleh lebih dari 100%'
          this.formPayload.amount = 100
        }
      } else if (this.result.order && numValue > this.result.order.total) {
        this.amountError = `Nominal tidak boleh lebih dari ${this.formatAmount(this.result.order.total)}`
        this.formPayload.amount = this.result.order.total
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.complaint-action-form {
  .form-group {
    margin-bottom: 1.5rem;
  }

  .amount-input-wrapper {
    position: relative;
    margin-bottom: 0.75rem;

    .form-control-lg {
      height: 50px;
      font-size: 1.2rem;
      padding: 0.75rem 1rem;
      border-radius: 8px;
      border: 1px solid #d8d6de;
      background-color: #fff;
      transition: all 0.15s ease-in-out;

      &:focus {
        border-color: #7367f0;
        box-shadow: 0 3px 10px 0 rgba(34,41,47,0.1);
      }

      &::placeholder {
        color: #b9b9c3;
        font-size: 1rem;
        font-weight: 400;
      }
    }

    input[type="number"] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }

    .input-prefix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1rem;
      color: #5e5873;
      font-size: 1rem;
      font-weight: 400;
      pointer-events: none;
      opacity: 0.7;
      z-index: 1;
    }

    .input-suffix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
      color: #6e6b7b;
      font-size: 1.1rem;
      font-weight: 400;
      pointer-events: none;
      z-index: 1;
    }

    // Fixed amount input styles
    .input-prefix + .form-control-lg {
      padding-left: 2.75rem;
    }

    // Percentage input styles
    .input-suffix + .form-control-lg {
      padding-right: 2rem;
    }
  }
  
  .amount-preview {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 0.75rem 1rem;
    margin-top: 0.5rem;

    .preview-content {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
    }

    .preview-text {
      flex: 1;
      
      .calculation {
        color: #6e6b7b;
        margin-bottom: 0.25rem;
      }

      .total {
        color: #7367f0;
        font-weight: 600;
        font-size: 1.1rem;
      }
    }
  }

  label {
    color: #5e5873;
    margin-bottom: 0.5rem;
  }

  .text-danger {
    color: #ea5455 !important;
    margin-top: 0.25rem;
    display: block;
  }
}

.winner-card {
  background: linear-gradient(to right, rgba(115, 103, 240, 0.04), rgba(115, 103, 240, 0.01));
  border: 1px solid rgba(115, 103, 240, 0.1);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  }

  .winner-header {
    border-bottom: 1px solid rgba(115, 103, 240, 0.1);
    padding-bottom: 1rem;

    h3 {
      color: #5e5873;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  .winner-badge {
    .badge {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      
      &.badge-light-success {
        background-color: rgba(40, 199, 111, 0.12);
        color: #28c76f;
      }
    }
  }

  .winner-details {
    .detail-item {
      font-size: 0.95rem;
      
      .font-weight-bold {
        color: #5e5873;
      }
    }
  }
}
</style>
