<!-- List Reports -->
<template>
  <div>
    <!-- Table Card -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Reports List</h5>
      </b-card-header>

      <div class="m-2">
        <div v-if="reports.length === 0">Loading...</div>
        <!-- Table with Buttons -->
        <vue-good-table
          v-else
          mode="remote"
          :total-rows="totalRecords"
          :columns="columns"
          :rows="reports"
          :rtl="false"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            searchFn: customSearch
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
            position: 'bottom'
          }"
          :line-numbers="true"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'created_at', type: 'desc' }
          }"
          compactMode
          styleClass="vgt-table striped bordered"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-search="onSearch"
          >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Actions -->
            <span v-if="props.column.field === 'actions'">
              <b-button
                variant="primary"
                size="sm"
                class="mr-1"
                @click="viewReport(props.row.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </span>

            <!-- Column: Reported Service -->
            <span v-else-if="props.column.field === 'talent_service.name'">
              {{ (props.row.talent_service && props.row.talent_service.name) || 'N/A' }}
            </span>

            <!-- Column: Report Reason -->
            <span v-else-if="props.column.field === 'reason.reason_text'">
              {{ (props.row.reason && props.row.reason.reason_text) || 'N/A' }}
            </span>

            <!-- Column: Reporter -->
            <span v-else-if="props.column.field === 'reporter.name'">
              {{ (props.row.reporter && props.row.reporter.name) || 'N/A' }}
            </span>

            <!-- Column: Description -->
            <span v-else-if="props.column.field === 'additional_description'">
              {{ props.row.additional_description || 'No description provided' }}
            </span>

            <!-- Column: Created At -->
            <span v-else-if="props.column.field === 'created_at'">
              {{ formatDate(props.row.created_at) }}
            </span>

            <!-- Default -->
            <span v-else>
              {{ props.formattedRow[props.column.field] || 'N/A' }}
            </span>
          </template>

          <!-- Empty State -->
          <div slot="emptystate" class="text-center">
            No reports found
          </div>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BBadge, BButton,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import axios from '@/libs/axios'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  name: 'ListReports',
  components: {
    BCard,
    BCardHeader,
    BBadge,
    BButton,
    VueGoodTable,
  },
  setup(props, { root }) {
    const reports = ref([])
    const searchTerm = ref('')
    const perPage = ref(15)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const sortColumn = ref('')
    const sortType = ref('asc')

    // Table Columns
    const columns = [
      {
        label: 'Reporter',
        field: 'reporter.name',
        sortable: true,
        globalSearchDisabled: false,
      },
      {
        label: 'Reported Service',
        field: 'talent_service.name',
        sortable: true,
        globalSearchDisabled: false,
      },
      {
        label: 'Report Reason',
        field: 'reason.reason_text',
        sortable: true,
        globalSearchDisabled: false,
      },
      {
        label: 'Description',
        field: 'additional_description',
        sortable: true,
        globalSearchDisabled: false,
      },
      {
        label: 'Created At',
        field: 'created_at',
        sortable: true,
        globalSearchDisabled: true,
      },
      {
        label: 'Actions',
        field: 'actions',
        sortable: false,
        globalSearchDisabled: true,
      },
    ]

    // Format Date
    const formatDate = (date) => {
      if (!date) return 'N/A'
      const options = { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
      return new Date(date).toLocaleDateString('en-US', options)
    }

    // Custom search function to handle nested properties
    const customSearch = (row, searchTerm) => {
      if (!searchTerm) return true
      
      const searchValue = String(searchTerm || '').toLowerCase()
      const reporterName = String((row.reporter && row.reporter.name) || '').toLowerCase()
      const serviceName = String((row.talent_service && row.talent_service.name) || '').toLowerCase()
      const reasonText = String((row.reason && row.reason.reason_text) || '').toLowerCase()
      const description = String(row.additional_description || '').toLowerCase()

      return reporterName.includes(searchValue) ||
             serviceName.includes(searchValue) ||
             reasonText.includes(searchValue) ||
             description.includes(searchValue)
    }

    // Fetch Reports
    const fetchReports = async () => {
      try {
        const response = await axios.get('/reports')
        console.log('API Response:', response.data)
        if (response.data && response.data.data) {
          reports.value = response.data.data.data || []
          totalRecords.value = response.data.data.total || 0
          perPage.value = response.data.data.per_page || 15
          currentPage.value = response.data.data.current_page || 1
          console.log('Reports loaded:', reports.value)
          console.log('Total records:', totalRecords.value)
        } else {
          console.error('Invalid data structure:', response.data)
          reports.value = []
          totalRecords.value = 0
        }
      } catch (error) {
        console.error('Error fetching reports:', error)
        reports.value = []
        totalRecords.value = 0
        root.$bvToast.toast('Error fetching reports', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    }

    // View Report Details
    const viewReport = id => {
      root.$router.push({ name: 'reports.show', params: { id } })
    }

    // Event handlers
    const onPageChange = (params) => {
      currentPage.value = params.currentPage
      fetchReports()
    }

    const onSortChange = (params) => {
      if (params && params.columnIndex !== undefined && columns[params.columnIndex]) {
        const { sortType: newSortType, columnIndex } = params
        sortColumn.value = columns[columnIndex].field
        sortType.value = newSortType || 'asc'
        fetchReports()
      }
    }

    const onSearch = (params) => {
      searchTerm.value = params.searchTerm
      fetchReports()
    }

    onMounted(() => {
      fetchReports()
    })

    return {
      reports,
      columns,
      searchTerm,
      perPage,
      totalRecords,
      viewReport,
      formatDate,
      customSearch,
      onPageChange,
      onSortChange,
      onSearch,
    }
  },
  watch: {
    reports: {
      handler(newVal) {
        console.log('Reports changed:', newVal)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.vgt-table {
  border: 1px solid #ebe9f1;
  border-radius: 0.5rem;

  th {
    font-weight: 600;
    background-color: #f3f2f7;
  }
}
</style> 