<template>
  <div v-if="chatData">
    <div
      v-if="chatData.length > 0"
      class="chats"
    >
      <div
        v-for="(msg, index) in chatData"
        :key="index"
        class="chat"
        :class="{'chat-left': msg.is_admin == false }"
      >
        <div class="chat-avatar">
          <b-avatar
            size="36"
            class="avatar-border-2 box-shadow-1"
            variant="transparent"
          />
        </div>
        <div class="chat-body">
          <div class="chat-content">
            <div class="d-flex justify-content-between">
              <small
                class="text-capitalize mr-2"
                :class="{ 'text-primary' : msg.sender_type == 'admin' }"
              >{{ msg.sender_type }}</small>
              <small class="mb-75">{{ msg.date }}</small>
            </div>
            <p>{{ msg.content_text }}</p>
            <p v-if="msg.content_file !== null">
              <a :href="msg.content_file" target="_blank">Buka File</a>
            </p>
            <small>{{ msg.time }}</small>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="chats"
    >
      <div class="card">
        <p class="text-center mb-0 py-2">
          Chat is empty.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: ['chatData'],
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
