<!-- Help Management Page -->
<template>
  <div>
    <!-- Page Header -->
    <b-card
      no-body
      class="mb-4"
    >
      <b-card-header>
        <h3 class="mb-0">Manajemen Bantuan</h3>
      </b-card-header>
    </b-card>

    <!-- Table Card -->
    <b-card>
      <!-- Table Filters -->
      <div class="d-flex justify-content-between align-items-center mb-3">
        <b-form-group
          class="mb-0 mr-3"
          label="Show entries"
        >
          <b-form-select
            v-model="perPage"
            :options="[10, 25, 50, 100]"
            class="w-75"
          />
        </b-form-group>

        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              placeholder="Search..."
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>

      <!-- Main Table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: filter
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage
        }"
        theme="my-theme"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Status Column -->
          <span
            v-if="props.column.field === 'status'"
            class="text-nowrap"
          >
            <b-badge :variant="getStatusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Actions Column -->
          <span v-else-if="props.column.field === 'actions'">
            <b-button
              v-b-tooltip.hover
              variant="primary"
              size="sm"
              class="mr-1"
              title="View Details"
              @click="viewDetails(props.row.id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card>

    <!-- Detail Modal -->
    <b-modal
      id="help-detail-modal"
      title="Detail Bantuan"
      size="lg"
      hide-footer
    >
      <b-card v-if="selectedHelp">
        <b-list-group>
          <b-list-group-item>
            <strong>ID:</strong> {{ selectedHelp.id }}
          </b-list-group-item>
          <b-list-group-item>
            <strong>User:</strong> {{ selectedHelp.user }}
          </b-list-group-item>
          <b-list-group-item>
            <strong>Type:</strong> {{ selectedHelp.type }}
          </b-list-group-item>
          <b-list-group-item>
            <strong>Description:</strong>
            <p class="mt-1 mb-0">{{ selectedHelp.description }}</p>
          </b-list-group-item>
          <b-list-group-item>
            <strong>Status:</strong>
            <b-badge
              :variant="getStatusVariant(selectedHelp.status)"
              class="ml-1"
            >
              {{ selectedHelp.status }}
            </b-badge>
          </b-list-group-item>
          <b-list-group-item>
            <strong>Created At:</strong> {{ selectedHelp.created_at }}
          </b-list-group-item>
        </b-list-group>

        <div class="mt-3">
          <b-button
            variant="secondary"
            @click="$bvModal.hide('help-detail-modal')"
          >
            Close
          </b-button>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BBadge,
  BModal,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  name: 'HelpManagement',
  components: {
    BCard,
    BCardHeader,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BBadge,
    BModal,
    BListGroup,
    BListGroupItem,
    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 10,
      filter: '',
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'User',
          field: 'user',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Created At',
          field: 'created_at',
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
        },
      ],
      rows: [
        {
          id: 1,
          user: 'John Doe',
          type: 'Pengecatan Gitar',
          description: 'Masalah dengan pengecatan gitar',
          status: 'Pending',
          created_at: '2024-02-07 14:30:00',
        },
        {
          id: 2,
          user: 'Jane Smith',
          type: 'Ketemu Bawa Gitarnya',
          description: 'Konfirmasi waktu pertemuan',
          status: 'Resolved',
          created_at: '2024-02-07 15:45:00',
        },
        // Add more dummy data as needed
      ],
      selectedHelp: null,
    }
  },
  methods: {
    getStatusVariant(status) {
      const variants = {
        Pending: 'warning',
        'In Progress': 'info',
        Resolved: 'success',
        Cancelled: 'danger',
      }
      return variants[status] || 'secondary'
    },
    viewDetails(id) {
      this.selectedHelp = this.rows.find(row => row.id === id)
      this.$bvModal.show('help-detail-modal')
    },
  },
}
</script>

<style lang="scss">
.my-theme {
  .vgt-table {
    thead th {
      background-color: #f8f8f8;
      border-bottom: 2px solid #ddd;
    }
    
    td {
      padding: 0.75rem;
      vertical-align: middle;
    }
  }
}

.badge {
  font-size: 0.9em;
  padding: 0.5em 0.75em;
}
</style> 